import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import products from './submodules/products';

const state = {
    product: null,
    stock: null,
    stocks: [],
    stocks_loading: false,
    stocks_loading_errors: {},
    quantity: null,
    comment: null,
    movement: {},
    movement_saving: false,
    movement_saving_errors: {},
};

const mutations = {
    updateProduct(state, product) {
        state.product = product;
    },
    updateMovement(state, movement) {
        state.movement = movement;
        state.comment = movement?.comment;
        state.product = movement?.product;
        state.quantity = movement?.quantity;
    },
    updateComment(state, comment) {
        state.comment = comment;
    },
    updateQuantity(state, quantity) {
        state.quantity = quantity;
    },
    updateStock(state, stock) {
        state.stock = stock;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
        state.stocks_loading_errors = {};
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading = false;
        state.stocks_loading_errors = errors;
    },
    updateStocks(state, stocks) {
        state.stocks = stocks;
        if (!state.stock && stocks.length) {
            state.stock = stocks[0].id;
        }
    },
    updateMovementSaving(state, saving) {
        state.movement_saving = saving;
        state.movement_saving_errors = {};
    },
    updateMovementSavingErrors(state, errors) {
        state.movement_saving = false;
        state.movement_saving_errors = errors;
    },
};

const actions = {

    fetchStocks({ commit, dispatch, state }, params) {
        commit('updateStocksLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/', {
                params: { limit: 1000, owned: true },
            })
                .then((response) => {
                    commit('updateStocks', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStocksLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateStocksLoading', false);
                })
        })
    },


    saveMovement({ commit, dispatch, state }, params) {
        commit('updateMovementSaving', true);

        const payload = {
            comment: state.comment,
        };

        let url = '/api/movements/';
        let method = axios.post;
        if (state.movement?.id) {
            url = `/api/movements/${state.movement.id}/`;
            method = axios.patch;
        } else {
            payload.stock = state.stock;
            payload.product = state.product;
            payload.quantity = state.quantity;
            payload.movement_type = "adjustment";
        }
        return new Promise((resolve, reject) => {
            method(url, payload)
                .then((response) => {
                    commit('updateMovementSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateMovementSavingErrors', error.details);
                    reject(error);
                });
        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateMovement', params.movement);
        dispatch('fetchStocks');
        dispatch('products/init');
        if (!params?.movement?.product) {
            dispatch('products/fetchProducts');
        } else {
            commit('products/updateProducts', [params.movement.product_data]);
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        products: products(),
    },
};
