import store from "@/stores/store";
import axios from "axios";
import utils from "@/stores/utils";
import documents from "./submodules/documents";
import catalogentries from "./submodules/catalogentries";
import invoices from "./submodules/invoices";

const state = {
    providerorder: null,
    providerorder_loaded: false,
    providerorder_loading: false,
    providerorder_loading_errors: null,
    providerorder_saving: false,
    providerorder_saving_errors: null,

    providerorder_setting_readyness: false,
    updating_providerorder_status: false,
    assigning_providerorder: false,

    deleting_providerorder: false,
    deleting_providerorder_errors: null,

    providerorderitem: false,
    providerorderitem_loading: false,
    providerorderitem_loading_errors: null,
    providerorderitem_saving: false,
    providerorderitem_saving_errors: null,
    providerorderitem_deleting: false,
    providerorderitem_deleting_errors: null,

    providerorderitems: null,
    providerorderitems_count: 0,
    providerorderitems_filters: { limit: 20, ordering: "product_owner,product_name" },
    providerorderitems_loaded: false,
    providerorderitems_loading: false,
    providerorderitems_loading_errors: null,
    providerorderitems_cancel_source: null,

    customers: [],
    customers_count: 0,
    customers_loading: false,
    customers_loading_errors: null,

    deliverysites: [],
    deliverysites_count: 0,
    deliverysites_loading: false,
    deliverysites_loading_errors: null,

    stocks: [],
    stocks_count: 0,
    stocks_loading: false,
    stocks_loading_errors: null,

    events: [],
    events_filters: {},
    events_loading: false,
    events_loaded: false,
    events_loading_errors: null,
    events_count: 0,

    receipts: [],
    receipts_filters: {},
    receipts_count: 0,
    receipts_loaded: false,
    receipts_loading: false,
    receipts_loading_errors: null,

    shipments: [],
    shipments_filters: {},
    shipments_count: 0,
    shipments_loaded: false,
    shipments_loading: false,
    shipments_loading_errors: null,


    providerorder_delegating: false,
    providerorder_billing: false,

    creating_receipt: false,

    productstrategy: [],
    productstrategy_loading: false,
};

const mutations = {
    updateProviderOrder(state, providerorder) {
        state.providerorder_loaded = true;

        if (providerorder && state.providerorder && state.providerorder.id == providerorder.id) {
            state.providerorder = providerorder;
            return;
        }

        state.providerorder = providerorder;
        state.productstrategy = [];
        state.productstrategy_loading = false;

        state.providerorderitems = [];
        state.providerorderitems_count = 0;
        state.providerorderitems_loaded = false;


        state.events = [];
        state.events_count = 0;
        state.events_loaded = false;
        state.events_loading_errors = null;
        if (!providerorder) {
            state.providerorderitems_filters = {
                offset: 0,
                limit: state.providerorderitems_filters.limit,
                ordering: "product_owner,product_name"
            };
            state.events_filters = { object_type: "order", ordering: "event_date" };
            return;
        }

        state.providerorderitems_filters = { offset: 0, limit: state.providerorderitems_filters.limit, ordering: "product_owner,product_name" };
        state.events_filters = {
            object_type: "order",
            object_id: providerorder.order_id,
            ordering: "event_date",
        };

        state.receipts = [];
        state.receipts_count = 0;
        state.receipts_loaded = false;
        state.receipts_filters = {
            offset: 0,
            limit: state.receipts_filters.limit,
        };
        if (state.providerorderitems_cancel_source) {
            state.providerorderitems_cancel_source.cancel("canceled");
            state.providerorderitems_cancel_source = null;
        }
    },
    updateProviderOrderLoading(state, loading) {
        state.providerorder_loading = loading;
    },
    updateProviderOrderLoadingErrors(state, errors) {
        state.providerorder_loaded = true;
        state.providerorder_loading_errors = errors;
    },
    updateProviderOrderSaving(state, saving) {
        state.providerorder_saving = saving;
    },
    updateProviderOrderSavingErrors(state, errors) {
        state.providerorder_saving_errors = errors;
    },

    updateSettingProviderOrderReadyness(state, setting) {
        state.providerorder_setting_readyness = setting;
    },
    updateUpdatingProviderOrderStatus(state, status) {
        state.updating_providerorder_status = status;
    },
    updateProviderOrderDeleting(state, deleting) {
        state.deleting_providerorder = deleting;
    },
    updateProviderOrderDeletingErrors(state, errors) {
        state.deleting_providerorder_errors = errors;
    },
    updateProviderOrderItem(state, providerorderitem) {
        state.providerorderitem = providerorderitem;
    },
    updateProviderOrderItemLoading(state, loading) {
        state.providerorderitem_loading = loading;
    },
    updateProviderOrderItemLoadingErrors(state, errors) {
        state.providerorderitem_loading_errors = errors;
    },
    updateProviderOrderItemSaving(state, saving) {
        state.providerorderitem_saving = saving;
    },
    updateProviderOrderItemSavingErrors(state, errors) {
        state.providerorderitem_saving_errors = errors;
    },
    updateProviderOrderItemDeleting(state, deleting) {
        state.providerorderitem_deleting = deleting;
    },
    updateProviderOrderItemDeletingErrors(state, errors) {
        state.providerorderitem_deleting_errors = errors;
    },
    updateProviderOrderItems(state, providerorderitems) {
        state.providerorderitems = providerorderitems;
        state.providerorderitems_loaded = true;
    },
    updateProviderOrderItemsCount(state, count) {
        state.providerorderitems_count = count;
    },
    updateProviderOrderItemsFilters(state, providerorderitems_filters) {
        state.providerorderitems_filters = {
            ...providerorderitems_filters,

        };
    },
    updateProviderOrderItemsLoading(state, loading) {
        state.providerorderitems_loading = loading;
    },
    updateProviderOrderItemsLoadingErrors(state, errors) {
        state.providerorderitems_loading_errors = errors;
    },
    updateProviderOrderItemsCancelSource(state, providerorderitems_cancel_source) {
        state.providerorderitems_cancel_source = providerorderitems_cancel_source;
    },
    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersCount(state, count) {
        state.customers_count = count;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },
    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
    },
    updateDeliverySitesCount(state, count) {
        state.deliverysites_count = count;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },
    updateStocks(state, stocks) {
        state.stocks = stocks;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },
    updateStocksCount(state, count) {
        state.stocks_count = count;
    },
    updateAssigningProviderOrder(state, assigning_providerorder) {
        state.assigning_providerorder = assigning_providerorder;
    },
    updateEvents(state, events) {
        state.events = events;
    },
    updateEventsLoading(state, events_loading) {
        state.events_loading = events_loading;
    },
    updateEventsLoadingErrors(state, events_loading_errors) {
        state.events_loading_errors = events_loading_errors;
    },
    updateEventsCount(state, events_count) {
        state.events_count = events_count;
    },
    updateReceipts(state, receipts) {
        state.receipts = receipts;
    },
    updateReceiptsLoading(state, receipts_loading) {
        state.receipts_loading = receipts_loading;
        state.receipts_loaded = true;
    },
    updateReceiptsLoadingErrors(state, receipts_loading_errors) {
        state.receipts_loading_errors = receipts_loading_errors;
    },
    updateReceiptsCount(state, receipts_count) {
        state.receipts_count = receipts_count;
    },
    updateReceiptsFilters(state, receipts_filters) {
        state.receipts_filters = Object.assign(
            {
                limit: state.receipts_filters.limit || 20,
                ordering: state.receipts_filters.ordering,
            },
            receipts_filters
        );
    },
    updateShipments(state, shipments) {
        state.shipments = shipments;
    },

    updateShipmentsLoading(state, shipments_loading) {
        state.shipments_loading = shipments_loading;
        state.shipments_loaded = true;
    },
    updateShipmentsLoadingErrors(state, shipments_loading_errors) {
        state.shipments_loading_errors = shipments_loading_errors;
    },
    updateShipmentsCount(state, shipments_count) {
        state.shipments_count = shipments_count;
    },
    updateShipmentsFilters(state, shipments_filters) {
        state.shipments_filters = Object.assign(
            {
                limit: state.shipments_filters.limit || 20,
                ordering: state.shipments_filters.ordering,
            },
            shipments_filters
        );
    },

    updateDelegatingProviderOrder(state, providerorder_delegating) {
        state.providerorder_delegating = providerorder_delegating;
    },
    updateProviderOrderBilling(state, providerorder_billing) {
        state.providerorder_billing = providerorder_billing;
    },

    updateCreatingReceipt(state, creating_receipt) {
        state.creating_receipt = creating_receipt;
    },

    updateProductStrategy(state, productstrategy) {
        state.productstrategy = productstrategy;
    },
    updateProductStrategyLoading(state, productstrategy_loading) {
        state.productstrategy_loading = productstrategy_loading;
    },
};

const getters = {
    productStrategyNeedsUserInput(state) {
        return state.productstrategy.some((product) => !product.target_product);
    },
};

const actions = {
    async fetchEvents({ commit, dispatch, state }, params) {

        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_event"
            ) == -1
        ) {
            return;
        }
        commit("updateEventsLoading", true);

        try {
            const response = await axios.get("/api/events/", {
                params: state.events_filters,
            });
            commit("updateEvents", response.data.results);
            commit("updateEventsCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateEventsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateEventsLoading", false);
        }
    },

    async fetchShipments({ commit, dispatch, state }, params) {

        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_shipment"
            ) == -1
        ) {
            return;
        }

        commit("updateShipmentsLoading", true);

        try {
            const response = await axios.get(`/api/providerorders/${state.providerorder.id}/shipments/`, {
                params: state.shipments_filters,
            });
            commit("updateShipments", response.data.results);
            commit("updateShipmentsCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateShipmentsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateShipmentsLoading", false);
        }
    },


    async fetchReceipts({ commit, dispatch, state }, params) {

        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_receipt"
            ) == -1
        ) {
            return;
        }

        commit("updateReceiptsLoading", true);

        try {
            const response = await axios.get(`/api/providerorders/${state.providerorder.id}/receipts/`, {
                params: state.receipts_filters,
            });
            commit("updateReceipts", response.data.results);
            commit("updateReceiptsCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateReceiptsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateReceiptsLoading", false);
        }
    },

    async fetchProviderOrder({ commit, dispatch, state }, params) {

        commit("updateProviderOrderLoading", true);
        commit("updateProviderOrderLoadingErrors", null);

        let url;
        if (params && params.providerorder_id) {
            url = `/api/providerorders/${params.providerorder_id}/`;
        } else if (state.providerorder) {
            url = `/api/providerorders/${state.providerorder.id}/`;
        } else {
            throw "No providerorder to fetch";
        }

        try {
            const response = await axios.get(url);
            commit("updateProviderOrderLoading", false);
            commit("updateProviderOrder", response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateProviderOrderLoading", false);
            commit("updateProviderOrderLoadingErrors", error.details);
            throw error;
        }
    },

    async csvExport({ commit, dispatch, state }, params) {

        const url = `/api/providerorderitems/?order=${state.providerorder.id}&format=csv`;
        window.open(url, "_blank");
    },

    async setProviderOrderReadyness({ commit, dispatch, state }, params) {

        const url = `/api/providerorders/${state.providerorder.id}/ready/`;
        commit("updateSettingProviderOrderReadyness", true);
        try {
            const response = await axios.put(url, { ready: params.ready, productstrategy: params.productstrategy });
            dispatch("fetchProviderOrder");
            dispatch("fetchEvents");
            dispatch("session/fetchStats", null, { root: true });
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit("updateSettingProviderOrderReadyness", false);
        }
    },


    async fetchProductStrategy({ commit, state }) {
        if (store.getters['session/current_entity_profile'] == 'operator') {
            return;
        }

        commit('updateProductStrategy', []);
        commit('updateProductStrategyLoading', true);

        try {
            let response = await axios.get(`/api/providerorders/${state.providerorder.id}/productstrategy/`);
            commit('updateProductStrategy', response.data.products);
            return response.data.products;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateProductStrategyLoading', false);
        }
    },

    async deleteProviderOrder({ commit, dispatch, state }, params) {

        const url = `/api/providerorders/${params.instance.id}/`;

        commit("updateProviderOrderDeleting", true);
        commit("updateProviderOrderDeletingErrors", null);

        try {
            const response = await axios.delete(url);
            commit("updateProviderOrderDeleting", false);
            await dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateProviderOrderDeleting", false);
            commit("updateProviderOrderDeletingErrors", error.details);
            throw error;
        }
    },

    async deleteProviderOrderItem({ commit, dispatch, state }, params) {

        commit("updateProviderOrderItemDeleting", true);
        commit("updateProviderOrderItemDeletingErrors", null);

        const url = `/api/providerorderitems/${params.instance.id}/`;

        try {
            const response = await axios.delete(url);
            commit("updateProviderOrderItemDeleting", false);
            await Promise.all([
                dispatch("fetchProviderOrder"),
                dispatch("fetchProviderOrderItems"),
                dispatch("fetchEvents"),
                dispatch("session/fetchStats", null, { root: true }),
            ]);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateProviderOrderItemDeleting", false);
            commit("updateProviderOrderItemDeletingErrors", error.details);
            throw error;
        }
    },

    async fetchProviderOrderItems({ commit, dispatch, state }, params) {

        commit("updateProviderOrderItemsLoading", true);
        commit("updateProviderOrderItemsLoadingErrors", null);

        if (state.providerorderitems_cancel_source) {
            state.providerorderitems_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit("updateProviderOrderItemsCancelSource", cancelSource);

        try {
            const response = await axios.get("/api/providerorderitems/", {
                params: {
                    order: state.providerorder.id,
                    stock: state.providerorder.stock,
                    ...state.providerorderitems_filters,
                },
                cancelToken: state.providerorderitems_cancel_source?.token
            });
            commit("updateProviderOrderItems", response.data.results);
            commit("updateProviderOrderItemsCount", response.data.count);
            commit("updateProviderOrderItemsCancelSource", null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit("updateProviderOrderItemsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateProviderOrderItemsLoading", false);
        }
    },

    async setProviderOrderStatus({ commit, dispatch, state }, params) {

        const url = `/api/providerorders/${state.providerorder.id}/status/`;

        commit("updateUpdatingProviderOrderStatus", true);

        try {
            const response = await axios.put(url, { status: params.status });
            dispatch("fetchProviderOrder");
            dispatch("fetchEvents");
            dispatch("fetchProviderOrderItems");
            dispatch("fetchReceipts");
            dispatch("fetchShipments");
            dispatch("invoices/fetchInvoices");
            dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit("updateUpdatingProviderOrderStatus", false);
        }
    },

    async init({ commit, dispatch, state }, { providerorder }) {

        await commit("updateProviderOrder", providerorder);

        await dispatch("documents/init", {
            filterTag: `providerorder:${providerorder.order_id}`,
            createTags: [`providerorder:${providerorder.order_id}`],
        });

        await dispatch("catalogentries/init", {
            url: `/api/providerorders/${providerorder.id}/catalogentries/`,
            filters: {
                exclude_order_products: providerorder.id,
                customer: providerorder.buyer,
                ordering: "name", type: 'product',
                provider: providerorder.seller,

            },
        });

        await dispatch("invoices/init", {
            url: `/api/providerorders/${providerorder.id}/invoices/`,
            filters: { order: providerorder.id, include_providerorders: true },
        });
    },

    update({ commit, dispatch, state }) {
        dispatch("fetchProductStrategy");
        dispatch("fetchProviderOrder");
        dispatch("fetchProviderOrderItems");
        dispatch("fetchEvents");
        dispatch("fetchReceipts");
        dispatch("fetchShipments");
        dispatch("invoices/fetchInvoices");
        dispatch("documents/fetchDocuments");
        dispatch("catalogentries/fetchCatalogEntriesStats");
        dispatch("catalogentries/fetchCatalogEntries");
        dispatch("session/fetchStats", null, { root: true });
    },

    async createReceipt({ commit, dispatch, state }, params) {

        const url = `/api/providerorders/${state.providerorder.id}/createreceipt/`;
        commit("updateCreatingReceipt", true);

        try {
            const response = await axios.post(url);
            dispatch("session/fetchStats", null, { root: true });
            dispatch("fetchProviderOrder");
            dispatch("fetchProviderOrderItems");
            dispatch("fetchReceipts");
            dispatch("fetchShipments");
            dispatch("fetchEvents");
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit("updateCreatingReceipt", false);
        }
    },

    downloadProviderOrderForm({ commit, dispatch, state }, params) {

        const url = `/api/providerorders/${state.providerorder.id}/form/`;
        window.open(url, "_blank");
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
    modules: {
        documents: documents(),
        catalogentries: catalogentries(),
        invoices: invoices(),
    },
};
