import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    invoiceitems: [],
    invoiceitems_url: '/api/invoiceitems/',
    invoiceitems_filters: { offset: 0, limit: 20, },
    invoiceitems_count: 0,
    invoiceitems_loaded: false,
    invoiceitems_loading: false,
    invoiceitems_loading_errors: null,

    invoiceitems_stats: {},
    invoiceitems_stats_loaded: false,
    invoiceitems_stats_loading: false,
    invoiceitems_stats_loading_errors: null,
});

const mutations = {
    updateInvoiceItems(state, invoiceitems) {
        state.invoiceitems = invoiceitems;
        state.invoiceitems_loaded = true;
        state.invoiceitems_loading = false;
        state.invoiceitems_loading_errors = null;
    },
    updateInvoiceItemsUrl(state, invoiceitems_url) {
        state.invoiceitems_url = invoiceitems_url;
    },
    updateInvoiceItemsCount(state, invoiceitems_count) {
        state.invoiceitems_count = invoiceitems_count;
    },
    updateInvoiceItemsFilters(state, invoiceitems_filters) {
        state.invoiceitems_filters = invoiceitems_filters;
    },
    updateInvoiceItemsLoading(state, invoiceitems_loading) {
        state.invoiceitems_loading = invoiceitems_loading;
    },
    updateInvoiceItemsLoadingErrors(state, invoiceitems_loading_errors) {
        state.invoiceitems_loading_errors = invoiceitems_loading_errors;
        state.invoiceitems_loading = false;
    },


    updateInvoiceItemsStats(state, invoiceitems_stats) {
        state.invoiceitems_stats = invoiceitems_stats;
        state.invoiceitems_stats_loaded = true;
    },
    updateInvoiceItemsStatsLoading(state, loading) {
        state.invoiceitems_stats_loading = loading;
    },
    updateInvoiceItemsStatsLoadingErrors(state, errors) {
        state.invoiceitems_stats_loading_errors = errors;
    },

};

const actions = {

    fetchInvoiceItems({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("billing.view_invoiceitem") == -1) {
            return;
        }

        commit('updateInvoiceItemsLoading', true);
        commit('updateInvoiceItemsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(state.invoiceitems_url, { params: state.invoiceitems_filters })
                .then((response) => {
                    commit('updateInvoiceItemsCount', response.data.count);
                    commit('updateInvoiceItems', response.data.results);
                    dispatch('fetchInvoiceItemsStats');
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateInvoiceItemsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateInvoiceItemsLoading', false);
                });

        });
    },


    async fetchInvoiceItemsStats({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("billing.view_allocation") == -1) {
            return;
        }

        commit('updateInvoiceItemsStatsLoading', true);
        commit('updateInvoiceItemsStatsLoadingErrors', null);

        try {
            const response = await axios.get('/api/invoiceitems/stats/', { params: state.invoiceitems_filters });
            commit('updateInvoiceItemsStatsLoading', false);
            commit('updateInvoiceItemsStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateInvoiceItemsStatsLoadingErrors', error.details);
            commit('updateInvoiceItemsStatsLoading', false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        commit('updateInvoiceItems', []);
        const default_filters = { offset: 0, limit: 20, ordering: '-creation_date' };
        commit('updateInvoiceItemsFilters', { ...default_filters, ...(params?.filters || {}) });
        if (params && params.url) {
            commit('updateInvoiceItemsUrl', params.url);
        }
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions,
});