import qs from "qs";
import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import documents from "./submodules/documents";
import invitations from "./submodules/invitations";

const state = {
    provider: null,
    provider_loading: false,
    provider_loading_errors: null,

    section: null,
    stats: null,

    receipts: [],
    receipts_count: 0,
    receipts_filters: { limit: 20, offset: 0, ordering: "-receipt_date" },
    receipts_loaded: false,
    receipts_loading: false,

    purchaseprices: [],
    purchaseprices_count: 0,
    purchaseprices_filters: { offset: 0, limit: 20 },
    purchaseprices_loaded: false,
    purchaseprices_loading: false,
    purchaseprices_loading_errors: null,

    importing_products: [],
};

const mutations = {
    updateProvider(state, provider) {
        if (state.provider && provider && state.provider.id == provider.id) {
            state.provider = provider;
            return;
        }
        state.provider = provider;

        state.stats = null;
        state.receipts = [];
        state.receipts_count = 0;
        state.receipts_loaded = false;
        state.receipts_loading = false;
        state.receipts_loading_errors = null;

        state.purchaseprices = [];
        state.purchaseprices_count = 0;
        state.purchaseprices_loaded = false;
        state.purchaseprices_loading = false;
        state.purchaseprices_loading_errors = null;

        if (!provider) {
            state.receipts_filters = { offset: 0, limit: state.receipts_filters.limit };
            state.purchaseprices_filters = {
                offset: 0,
                limit: state.purchaseprices_filters.limit,
            };
            return;
        }

        state.receipts_filters = {
            provider: provider.id,
            offset: 0,
            limit: state.receipts_filters.limit,
            ordering: "-receipt_date",
        };
        state.purchaseprices_filters = {
            provider: provider.id,
            offset: 0,
            include_archived_providers: provider.archived,
            limit: state.purchaseprices_filters.limit,
            ordering: "name",
        };
    },

    updateReceipts(state, receipts) {
        state.receipts = receipts;
    },
    updateReceiptsCount(state, count) {
        state.receipts_count = count;
    },
    updateReceiptsFilters(state, filters) {
        state.receipts_filters = Object.assign(filters || {}, {
            provider: state.provider.id,
        });
    },

    updateReceiptsLoading(state, loading) {
        state.receipts_loading = loading;
    },
    updateReceiptsLoadingErrors(state, errors) {
        state.receipts_loading_errors = errors;
    },

    updateProviderLoading(state, loading) {
        state.provider_loading = loading;
    },
    updateProviderLoadingErrors(state, errors) {
        state.provider_loading = false;
        state.provider_loading_errors = errors;
    },

    updateProviderDeleting(state, deleting) {
        state.provider_deleting = deleting;
    },

    updatePurchasePrices(state, purchaseprices) {
        state.purchaseprices = purchaseprices;
    },
    updatePurchasePricesFilters(state, purchaseprices_filters) {
        state.purchaseprices_filters = Object.assign(
            { provider: state.provider.id },
            purchaseprices_filters
        );
    },
    updatePurchasePricesCount(state, purchaseprices_count) {
        state.purchaseprices_count = purchaseprices_count;
    },
    updatePurchasePricesLoading(state, loading) {
        state.purchaseprices_loading = loading;
    },
    updatePurchasePricesLoadingErrors(state, errors) {
        state.purchaseprices_loading_errors = errors;
    },

    updateStats(state, stats) {
        state.stats = stats;
    },
    updateSection(state, section) {
        state.section = section;
    },
    addImportingProducts(state, products) {
        state.importing_products = state.importing_products.concat(products);
    },
    removeImportingProducts(state, products) {
        state.importing_products = state.importing_products.filter((product) => {
            return !products.includes(product);
        });
    },
};

const actions = {
    exportToCSV({ commit, dispatch, state }, params) {

        let filters = {
            format: "csv",
            limit: 1000,
            provider: state.provider.id,
        };
        const queryparams = qs.stringify(filters, { arrayFormat: "repeat" });

        window.open(`/api/providerproducts/?${queryparams}`, "_blank");
    },

    fetchPurchasePrices({ commit, dispatch, state }, params) {


        commit("updatePurchasePricesLoading", true);
        commit("updatePurchasePricesLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get(`/api/providercatalogentries/`, {
                    params: { ...state.purchaseprices_filters, provider: state.provider.id },
                })
                .then((response) => {
                    commit("updatePurchasePrices", response.data.results);
                    commit("updatePurchasePricesCount", response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit("updatePurchasePricesLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    commit("updatePurchasePricesLoading", false);
                });
        });
    },

    fetchReceipts({ commit, dispatch, state }, params) {

        commit("updateReceiptsLoading", true);
        commit("updateReceiptsLoadingErrors", null);

        return new Promise((resolve, reject) => {
            axios
                .get("/api/receipts/", {
                    params: state.receipts_filters,
                })
                .then((response) => {
                    commit("updateReceiptsLoading", false);

                    commit("updateReceipts", response.data.results);
                    commit("updateReceiptsCount", response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit("updateReceiptsLoading", false);
                    commit("updateReceiptsLoadingErrors", error.details);
                    reject(error);
                });
        });
    },

    fetchProvider({ commit, dispatch, state }, params) {

        commit("updateProviderLoading", true);
        commit("updateProviderLoadingErrors", null);

        let url = `/api/providers/${state.provider.id}/`;

        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((response) => {
                    commit("updateProvider", response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateProviderLoadingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch("fetchStats");
                    commit("updateProviderLoading", false);
                });
        });
    },

    deleteProvider({ commit, dispatch, state }, params) {

        commit("updateProviderDeleting", true);

        const url = `/api/providers/${params.instance.id}/`;
        return new Promise((resolve, reject) => {
            axios
                .delete(url)
                .then((response) => {
                    commit("updateProviderDeleting", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    commit("updateProviderDeleting", false);
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },


    fetchStats({ commit, dispatch, state }, params) {
        axios.get(`/api/providers/${state.provider.id}/stats/`).then((response) => {
            commit("updateStats", response.data);
        });
    },

    init({ commit, dispatch, state }, params) {
        commit("updateProvider", params.provider);
        commit("updateStats", null);
        dispatch("fetchStats");
        if (!params.provider) {
            return;
        }
        dispatch("documents/init", {
            filterTag: `provider:${params.provider.id}`,
            createTags: [`provider:${params.provider.id}`],
        });
        dispatch("documents/fetchDocuments");
        dispatch("invitations/init", { filters: { provider: params.provider.id } });
        if (!state.provider.entity) {
            dispatch("invitations/fetchInvitations");
        }
    },

    updateProductsPrices({ commit, dispatch, state }, prices) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/providers/${state.provider.id}/productprices/`, prices)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    async inviteProvider({ commit, dispatch, state }, params) {

        try {
            await dispatch("invitations/saveInvitation", {
                data: {
                    provider: state.provider.id,
                    email_or_safetee_id: params.email_or_safetee_id,
                }
            });
        } finally {

            dispatch("invitations/fetchInvitations");
        }
    },

    async cancelProviderInvitation({ commit, dispatch, state }, params) {

        try {
            await dispatch("invitations/deleteInvitation", {
                instance: state.invitations.invitations[0],
            });
        } finally {

            dispatch("invitations/fetchInvitations");
        }
    },

    async deleteCatalogEntry({ commit, dispatch, state }, params) {

        try {
            await axios.delete(`/api/providercatalogentries/${params.catalogentry.id}/`);
        } finally {
            dispatch("fetchPurchasePrices");
        }
    },


    async createSaleProduct({ commit, dispatch, state }, params) {
        commit("addImportingProducts", params.catalogentries);
        try {
            const response = await axios.post(`/api/importproducts/`, {
                products: params.products
            });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            dispatch("fetchPurchasePrices");
            commit("removeImportingProducts", params.products);
        }
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
        invitations: invitations(),
    },
};
