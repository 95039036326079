import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import synchronize from './submodules/synchronize';
import store from '@/stores/store';


const state = {
    provider: null,
    providers: [],
    providers_loaded: false,
    providers_loading: false,
    providers_loading_errors: null,

    producttypes: [],
    producttypes_loading: false,
    producttypes_loaded: false,
    producttypes_loading_errors: null,
};


const mutations = {
    updateProviders(state, providers) {
        state.providers = providers;
        state.providers_loaded = true;
        state.providers_loading = false;
    },
    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
        if (loading) {
            state.providers_loading_errors = null;
            state.providers_loaded = false;
        }
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading_errors = errors;
        state.providers_loading = false;
        state.providers_loaded = true;
    },

    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
        state.producttypes_loaded = true;
        state.producttypes_loading = false;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
        if (loading) {
            state.producttypes_loading_errors = null;
            state.producttypes_loaded = false;
        }
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
        state.producttypes_loading = false;
        state.producttypes_loaded = true;
    },
    updateProvider(state, provider) {
        state.provider = provider;
    },
};

const actions = {
    init({ commit, dispatch, state }, params) {
        commit('updateProvider', params.provider);
        if (!params.provider) {
            dispatch("fetchProviders");
        } else {
            commit("synchronize/updateExtraParams", { provider: params.provider });
        }
        dispatch("fetchProductTypes");
    },

    changeProvider({ commit, dispatch, state }, provider) {
        commit("updateProvider", provider);
        commit("synchronize/updateExtraParams", { provider });
    },

    fetchProviders({ commit, dispatch, state }, params) {
        commit('updateProvidersLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/providers/', {
                params: { profile: "provider", limit: 1000, ordering: "name" },
            })
                .then((response) => {
                    commit('updateProviders', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProvidersLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchProductTypes({ commit, dispatch, state }, params) {
        commit('updateProductTypesLoading', true);

        return new Promise((resolve, reject) => {

            axios.get('/api/producttypes/', { params: { limit: 500 } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,

    actions,
    mutations,
    modules: {
        synchronize: synchronize(),
    }
};
