import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import store from "@/stores/store";

const state = {
    customers: [],
    customers_loading: false,
    customers_loading_errors: null,

    deliverysites: [],
    deliverysites_loading: false,
    deliverysites_loading_errors: null,

    providerorders: [],
    providerorders_loading: false,
    providerorders_loading_errors: null,

    providerorderitem: {},
    providerorderitem_saving: false,
    providerorderitem_saving_errors: {},

    provider: null,
    product: {},
    products_filters: {},
};

const mutations = {
    updateProvider(state, provider) {
        state.provider = provider;
        state.providerorders_filters = {
            exclude_readonly: true,
            ready: false,
            ordering: "id",
            limit: 1000,
        };
    },

    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },

    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },

    updateProviderOrders(state, providerorders) {
        state.providerorders = providerorders;
    },
    updateProviderOrdersLoading(state, loading) {
        state.providerorders_loading = loading;
    },
    updateProviderOrdersLoadingErrors(state, errors) {
        state.providerorders_loading_errors = errors;
    },

    updateProviderOrderItemSaving(state, saving) {
        state.providerorderitem_saving = saving;
    },
    updateProviderOrderItemSavingErrors(state, errors) {
        state.providerorderitem_saving_errors = errors;
    },

    updateProviderOrderItem(state, providerorderitem) {
        state.providerorderitem = providerorderitem;
        state.providerorders_filters = {
            exclude_readonly: true,
            ready: false,
            ordering: "id",
            limit: 1000,
            seller: state.provider,
        };

        state.products_filters = {
            product: state.providerorderitem.product,
        };
        state.providerorderitem_saving_errors = [];
    },

    updateStockProduct(state, product) {
        state.product = product;
    },
};
const actions = {
    async fetchDeliverySites({ commit, rootGetters, state }, params) {
        if (
            rootGetters["session/current_user_permissions"].indexOf(
                "core.view_deliverysite"
            ) === -1
        ) {
            return;
        }

        commit("updateDeliverySitesLoading", true);
        commit("updateDeliverySitesLoadingErrors", null);

        const customer_id = params.customer_id;

        try {
            const response = await axios.get(
                `/api/deliverysites/`,
                { params: { limit: 1000 } }
            );
            commit("updateDeliverySites", response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateDeliverySitesLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateDeliverySitesLoading", false);
        }
    },

    async fetchProviderOrders({ commit, rootGetters, state }, params) {
        if (
            rootGetters["session/current_user_permissions"].indexOf(
                "order.view_order"
            ) === -1
        ) {
            return;
        }

        commit("updateProviderOrdersLoading", true);
        commit("updateProviderOrdersLoadingErrors", null);

        try {
            console.log("XX", state.providerorders_filters);
            const response = await axios.get("/api/providerorders/", {
                params: state.providerorders_filters
            });
            commit("updateProviderOrders", response.data.results);
            if (response.data.results.length > 0 && !state.providerorderitem.id) {
                commit("updateProviderOrderItem", {
                    ...state.providerorderitem,
                    order: response.data.results[0].id,
                });
            }
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateProviderOrdersLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateProviderOrdersLoading", false);
        }
    },

    async saveProviderOrderItem({ commit, rootGetters, state }, params) {
        if (
            rootGetters["session/current_user_permissions"].indexOf(
                "order.add_orderitem"
            ) === -1 &&
            !state.providerorderitem.id
        ) {
            throw "Permission denied"
        }
        if (
            rootGetters["session/current_user_permissions"].indexOf(
                "order.change_orderitem"
            ) === -1 &&
            state.providerorderitem.id
        ) {
            throw "Permission denied"
        }

        commit("updateProviderOrderItemSaving", true);
        commit("updateProviderOrderItemSavingErrors", {});

        let url = "/api/providerorderitems/";
        let method = axios.post;

        if (state.providerorderitem.id) {
            url = `/api/providerorderitems/${state.providerorderitem.id}/`;
            method = axios.put;
        }
        try {
            return await method(url, state.providerorderitem);
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateProviderOrderItemSavingErrors", error.details);
            throw error;
        } finally {
            commit("updateProviderOrderItemSaving", false);
        }
    },

    async deleteProviderOrderItem({ commit, rootGetters, state }, params) {
        if (
            rootGetters["session/current_user_permissions"].indexOf(
                "order.delete_orderitem"
            ) === -1
        ) {
            return;
        }

        try {
            return await axios.delete(
                `/api/providerorderitems/${state.providerorderitem.id}/`
            );
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async init({ commit, state, dispatch }, params) {

        const providerorderitem = JSON.parse(JSON.stringify(
            {
                count: params.product?.packaging,
                ...(params.providerorderitem || {})

            }));
        if (params.product?.stock_data) {
            providerorderitem.count = Math.min(
                providerorderitem.count,
                params.product?.stock_data?.count
            );
        }
        commit('updateProvider', params.provider);
        commit('updateProviderOrderItem', providerorderitem);
        if (!providerorderitem.order) {
            dispatch('fetchProviderOrders');
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
