import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    url: '/api/invoices/',
    invoices: [],
    invoices_count: 0,
    invoices_loading: false,
    invoices_loaded: false,
    invoices_loading_errors: null,
    invoices_filters: { offset: 0, limit: 20, },
});


const mutations = {

    updateInvoices(state, invoices) {
        state.invoices = invoices;
    },
    updateInvoicesCount(state, count) {
        state.invoices_count = count;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoaded(state, loaded) {
        state.invoices_loaded = loaded;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoicesFilters(state, filters) {
        state.invoices_filters = filters;
    },
    updateUrl(state, url) {
        state.url = url;
    },
};

const actions = {

    async fetchInvoices({ commit, state, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.view_invoice") === -1) {
            return;
        }

        commit('updateInvoicesLoading', true);
        commit('updateInvoicesLoadingErrors', null);

        try {
            const response = await axios.get(state.url, { params: state.invoices_filters });
            commit('updateInvoices', response.data.results);
            commit('updateInvoicesCount', response.data.count);
            commit('updateInvoicesLoaded', true);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateInvoicesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateInvoicesLoading', false);
        }
    },

    init({ commit, state }, params) {
        if (params.url) {
            commit('updateUrl', params.url);
        }
        commit('updateInvoices', []);
        commit('updateInvoicesLoaded', false);
        commit('updateInvoicesFilters', Object.assign({}, state.invoices_filters, params.filters));
    },

};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});