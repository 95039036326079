var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-block d-sm-flex mb-8 flex-wrap title__wrapper align-center flex-wrap",
    "class": _vm.className
  }, [_c('div', {
    staticClass: "d-flex flex-grow-1 align-center"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.back ? _c('v-btn', {
    staticClass: "px-0 b mr-5 white back__button mr-2 d-none d-sm-block",
    attrs: {
      "text": "",
      "small": "",
      "cusor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.iconName("back")))])], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.icon ? _c('v-icon', {
    staticClass: "mr-2 d-none d-sm-block",
    attrs: {
      "color": "primary-dark",
      "size": "50"
    }
  }, [_vm._v(_vm._s(_vm.iconName(_vm.icon)))]) : _vm._e(), _c('div', {
    staticClass: "d-sm-flex d-block align-baseline"
  }, [_c('div', {
    staticClass: "text-h1 primary-dark--text title__text"
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _vm.tags.length ? _vm._l(_vm.tags, function (tag) {
    return _c('v-chip', {
      key: tag,
      staticClass: "ml-2",
      attrs: {
        "color": "primary-dark white--text font-weight-bold "
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }) : _vm._e()], 2), _vm.subtitle ? _c('div', {
    staticClass: "text-subtitle-2 mt-0 ml-2 primary-grey--text",
    domProps: {
      "textContent": _vm._s(_vm.subtitle)
    }
  }) : _vm._e(), _vm.helpContentId && _vm.uniqueIdExists ? _c('v-icon', {
    staticClass: "ml-4",
    attrs: {
      "left": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openHelp(_vm.helpContentId);
      }
    }
  }, [_vm._v("help_outline")]) : _vm._e()], 1)], 1)], 1)])]), _vm.ordered_actions && _vm.ordered_actions.length ? _c('div', {
    staticClass: "d-flex flex-wrap justify-end flex-grow-1 w-sm-100"
  }, [_vm._l(_vm.ordered_actions, function (action, index) {
    return [!action.items ? _c('v-btn', {
      key: action.action,
      staticClass: "ml-2 my-2 px-7 w-sm-auto w-100",
      attrs: {
        "loading": action.loading,
        "light": !action.main,
        "disabled": !_vm.actionsEnabled || action.disabled,
        "text": _vm.$vuetify.breakpoint.name == 'xs' ? false : !action.main,
        "color": action.main ? 'primary' : _vm.$vuetify.breakpoint.name == 'xs' ? 'primary-light' : 'primary',
        "elevation": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(action);
        }
      }
    }, [action.icon ? _c('v-icon', {
      attrs: {
        "small": "",
        "color": action.main ? 'white' : action.color || 'primary-light',
        "left": ""
      }
    }, [_vm._v(_vm._s(_vm.iconName(action.icon) || action.icon))]) : _vm._e(), _c('div', {
      "class": action.main ? 'white--text' : 'primary-text'
    }, [_vm._v(" " + _vm._s(action.label) + " ")])], 1) : _c('v-menu', {
      key: index,
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            key: action.action,
            staticClass: "ml-2 my-2 px-7 w-sm-auto w-100",
            attrs: {
              "light": "",
              "elevation": "0",
              "text": _vm.$vuetify.breakpoint.name == 'xs' ? false : !action.main,
              "color": action.main ? 'primary' : _vm.$vuetify.breakpoint.name == 'xs' ? 'primary-light' : 'primary'
            }
          }, 'v-btn', attrs, false), on), [action.icon ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": action.main ? 'white' : 'primary-light',
              "left": ""
            }
          }, [_vm._v(_vm._s(_vm.iconName(action.icon) || action.icon))]) : _vm._e(), _c('div', {
            "class": action.main ? 'white--text' : 'primary-text'
          }, [_vm._v(" " + _vm._s(action.label) + " ")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', _vm._l(action.items, function (item, index) {
      return _c('v-list-item', {
        key: index,
        on: {
          "click": function click($event) {
            return _vm.actionClicked(item);
          }
        }
      }, [item.icon ? _c('v-icon', {
        attrs: {
          "small": "",
          "left": "",
          "color": item.color
        }
      }, [_vm._v(_vm._s(_vm.iconName(item.icon) || item.icon))]) : _vm._e(), _vm._v(_vm._s(item.label) + " ")], 1);
    }), 1)], 1)];
  })], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }