import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    stock: null,
    products: [],
    products_count: 0,
    products_filters: { offset: 0, limit: 20, ordering: 'name', stock_tracking: true },
    products_loading: false,
    products_loading_errors: null,
    products_cancel_source: null,

    products_stats: {},
    products_stats_loading: false,
    products_stats_loading_errors: null,
    products_stats_cancel_source: null,
    selected_items: [],
};

const mutations = {
    updateProducts(state, products) {
        state.products = products;
    },
    updateProductsFilters(state, products_filters) {
        state.products_filters = products_filters;
        if (state.stock?.id != products_filters.stock) {
            state.stock = products_filters?.stock;
        }
    },
    updateProductsCount(state, products_count) {
        state.products_count = products_count;
    },
    updateProductsLoading(state, loading) {
        state.products_loading = loading;
    },
    updateProductsLoadingErrors(state, errors) {
        state.products_loading_errors = errors;
    },
    updateProductsCancelSource(state, source) {
        state.products_cancel_source = source;
    },
    updateProductsStats(state, products_stats) {
        state.products_stats = products_stats;
    },
    updateProductsStatsLoading(state, loading) {
        state.products_stats_loading = loading;
    },
    updateProductsStatsLoadingErrors(state, errors) {
        state.products_stats_loading_errors = errors;
    },
    updateProductsStatsCancelSource(state, source) {
        state.products_stats_cancel_source = source;
    },
    updateStock(state, stock) {
        state.stock = stock;
    },
    updateSelectedItems(state, items) {
        state.selected_items = items;
    },
};

const actions = {

    async bulkAction({ commit, dispatch, state }, params) {
        try {
            let data = {
                products: state.selected_items.map((item) => item.id),
                action: params.action,
                payload: params.payload || {},
            };
            const response = await axios.post('/api/products/bulk/', data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },
    fetchStock({ commit, dispatch, state }, params) {
        if (!params.stock) {
            commit('updateStock', null);
            return;
        }

        let url = `/api/stocks/${params.stock}/`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then((response) => {
                    commit('updateStock', response.data);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                })
        })
    },

    exportProductsAsCSV({ state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.products_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.stock_tracking = true;
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/products/?" + queryparams);
    },

    async fetchProducts({ commit, state, dispatch, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_product") === -1) {
            return;
        }
        commit('updateProductsLoading', true);
        commit('updateProductsLoadingErrors', null);

        if (state.products_cancel_source) {
            state.products_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateProductsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/products/', {
                params: state.products_filters,
                cancelToken: state.products_cancel_source?.token,
            });
            commit('updateProducts', response.data.results);
            commit('updateProductsCount', response.data.count);
            commit('updateProductsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateProductsLoadingErrors', error.details);
            commit('updateProductsCancelSource', null);
            throw error;
        } finally {
            commit('updateProductsLoading', false);
            dispatch('fetchProductsStats');
        }
    },

    async fetchProductsStats({ commit, state }) {
        commit('updateProductsStatsLoading', true);
        commit('updateProductsStatsLoadingErrors', null);

        if (state.products_stats_cancel_source) {
            state.products_stats_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateProductsStatsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/products/stats/', {
                params: state.products_filters,
                cancelToken: state.products_stats_cancel_source?.token,
            });
            commit('updateProductsStats', response.data);
            commit('updateProductsStatsCancelSource', null);
            return response.data;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateProductsStatsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductsStatsLoading', false);
        }
    },

    async changeProductsFilters({ commit, dispatch, state }, filters) {
        commit('updateProductsFilters', filters);
        await dispatch('fetchStock', filters);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};