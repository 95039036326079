import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import synchronize from './submodules/synchronize';
import store from '@/stores/store';


const state = {
    stocks: [],
    stocks_loaded: false,
    stocks_loading: false,
    stocks_loading_errors: null,

    customer: null,
    customers: [],
    customers_loaded: false,
    customers_loading: false,
    customers_loading_errors: null,

    producttypes: [],
    producttypes_loading: false,
    producttypes_loaded: false,
    producttypes_loading_errors: null,
};


const mutations = {
    updateStocks(state, stocks) {
        state.stocks = stocks;
        state.stocks_loaded = true;
        state.stocks_loading = false;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
        if (loading) {
            state.stocks_loading_errors = null;
            state.stocks_loaded = false;
        }
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
        state.stocks_loading = false;
        state.stocks_loaded = false;
    },
    updateStocksCount(state, stocks_count) {
        state.stocks_count = stocks_count;
    },

    updateCustomers(state, customers) {
        state.customers = customers;
        state.customers_loaded = true;
        state.customers_loading = false;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
        if (loading) {
            state.customers_loading_errors = null;
            state.customers_loaded = false;
        }
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
        state.customers_loading = false;
        state.customers_loaded = true;
    },

    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
        state.producttypes_loaded = true;
        state.producttypes_loading = false;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
        if (loading) {
            state.producttypes_loading_errors = null;
            state.producttypes_loaded = false;
        }
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
        state.producttypes_loading = false;
        state.producttypes_loaded = true;
    },
    updateCustomer(state, customer) {
        state.customer = customer;
    },
};

const actions = {
    init({ commit, dispatch, state }, params) {
        commit('updateCustomer', null);
        if (store.getters['session/current_user_mode'] == "operator") {
            dispatch("fetchCustomers");
        }
        dispatch("fetchProductTypes");
        dispatch("fetchStocks");
    },

    changeCustomer({ commit, dispatch, state }, customer) {
        commit("updateCustomer", customer);
        commit("synchronize/updateExtraParams", { customer });
    },

    fetchStocks({ commit, dispatch, state }, params) {
        commit('updateStocksLoading', true);
        return new Promise((resolve, reject) => {
            axios.get('/api/stocks/', {
                params: { limit: 500, owned: true },
            })
                .then((response) => {
                    commit('updateStocks', response.data.results);
                    commit('updateStocksCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateStocksLoading', false);
                    reject(error);
                })
        });
    },

    fetchCustomers({ commit, dispatch, state }, params) {
        commit('updateCustomersLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/entities/', {
                params: { profile: "customer", limit: 500, ordering: "name" },
            })
                .then((response) => {
                    commit('updateCustomers', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateCustomersLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    fetchProductTypes({ commit, dispatch, state }, params) {
        commit('updateProductTypesLoading', true);

        return new Promise((resolve, reject) => {

            axios.get('/api/producttypes/', { params: { limit: 500 } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,

    actions,
    mutations,
    modules: {
        synchronize: synchronize(),
    }
};
