import qs from 'qs';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    invoice: null,
    invoice_saving: false,
    invoice_saving_errors: {},

    customers: [],
    customers_loading: false,
    customers_loading_errors: null,

    billingaccounts: [],
    billingaccounts_loading: false,
    billingaccounts_loading_errors: null,
};

const mutations = {

    updateInvoice(state, invoice) {
        state.invoice = invoice;
        state.invoice_saving = false;
        state.invoice_saving_errors = {};
    },

    updateInvoiceSaving(state, invoice_saving) {
        state.invoice_saving = invoice_saving;
    },
    updateInvoiceSavingErrors(state, invoice_saving_errors) {
        state.invoice_saving_errors = invoice_saving_errors;
    },

    updateCustomers(state, customers) {
        state.customers = customers;
        state.customers_loading = false;
        state.customers_loading_errors = null;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
        state.customers_loading_errors = null;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading = false;
        state.customers_loading_errors = errors;
    },

    updateBillingAccounts(state, billingaccounts) {
        state.billingaccounts = billingaccounts;
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = null;
    },
    updateBillingAccountsLoading(state, billingaccounts_loading) {
        state.billingaccounts_loading = billingaccounts_loading;
    },
    updateBillingAccountsLoadingErrors(state, billingaccounts_loading_errors) {
        state.billingaccounts = [];
        state.billingaccounts_loading = false;
        state.billingaccounts_loading_errors = billingaccounts_loading_errors;
    },
};

const actions = {


    fetchBillingAccounts({ commit, dispatch, state }, params) {
        commit('updateBillingAccountsLoading', true);
        commit('updateBillingAccountsLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/billingaccounts/')
                .then((response) => {
                    commit('updateBillingAccounts', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateBillingAccountsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateBillingAccountsLoading', false);
                })
        })
    },

    fetchCustomers({ commit, dispatch, state }, params) {
        commit('updateCustomersLoading', true);

        return new Promise((resolve, reject) => {


            this.request = axios.get("/api/customers/", { params: { limit: 1000, ordering: 'name', parent: store.getters['session/current_entity_id'] } })
                .then((response) => {
                    commit('updateCustomers', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCustomersLoadingErrors', error.details);
                    reject(error);
                })
        });
    },

    saveInvoice({ commit, dispatch, state }, params) {
        commit('updateInvoiceSaving', true);

        let url = '/api/invoices/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/invoices/${params.instance.id}/`;
            method = axios.put;
        }

        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    commit('updateInvoiceSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateInvoiceSavingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                    commit('updateInvoiceSaving', false);
                });
        });
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
