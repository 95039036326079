import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import store from '@/stores/store';
import receipts from './submodules/receipts';

const state = {
    section: null,

    receipts: [],
    receipts_filters: { limit: 20, ordering: '-receipt_date' },
    receipts_count: 0,
    receipts_loading: false,
    receipts_loading_errors: null,
    receipts_cancel_source: null,

    demo_checked: false,
    demo_started: false,

    receipts_stats: {},
    receipts_stats_loading: false,
    receipts_stats_loading_errors: null,
    receipts_stats_cancel_source: null,

};

let getModeFilters = function (section) {
    if (section == 'selfhandling_waiting') {
        return {
            ready: true,
            readonly: false,
            status: 'waiting',
            exclude_readonly: true,
            delegated: false,
        };
    } else if (section == 'selfhandling_toplace') {
        return {
            ready: true,
            readonly: true,
            placed: false,
            exclude_readonly: false,
            delegated: false,
        };

    } else if (section == 'selfhandling_processing') {
        return {
            ready: true,
            readonly: false,
            status__neq: 'waiting',
            exclude_readonly: true,
            delegated: false,
        };
    } else if (section == 'delegated') {
        return {
            ready: true,
            readonly: false,
            exclude_readonly: true,
            delegated: true,
        };
    } else if (section == 'delegated_waiting') {
        return {
            ready: true,
            readonly: false,
            status: 'waiting',
            exclude_readonly: true,
            delegated: true,
        };
    } else if (section == 'delegated_processing') {
        return {
            ready: true,
            readonly: false,
            status__neq: 'waiting',
            exclude_readonly: true,
            delegated: true,
        };

    } else if (section == 'drafts') {
        return {
            ready: false,
            readonly: false,
        };
    } else if (section == 'waiting') {
        return {
            ready: true,
            readonly: false,
            status: "waiting",
        };
    } else if (section == 'processing') {
        return {
            ready: true,
            readonly: false,
            status__neq: "waiting",
        };
    } else if (section == 'toplace') {
        return {
            ready: true,
            readonly: true,
            placed: false,
        };
    } else if (section == 'all') {
        return {
            exclude_readonly: false,
        };
    }
};

const mutations = {
    updatePendingReceiptsCount(state, count) {
        state.pending_receipts_count = count;
    },
    updateToPlaceReceiptsCount(state, count) {
        state.toplace_receipts_count = count;
    },
    updateReceipts(state, receipts) {
        state.receipts = receipts;
    },
    updateSection(state, section) {
        state.section = section;
        state.receipts = [];
        state.receipts_count = 0;

        state.receipts_filters = {
            offset: 0,
            limit: state.receipts_filters.limit || 20,
            search: state.receipts_filters.search,
            ordering: state.receipts_filters.ordering,
            customer: state.receipts_filters.customer,
            provider: state.receipts_filters.provider,
        };
        state.receipts_filters = Object.assign({}, state.receipts_filters, getModeFilters(state.section));
    },
    updateReceiptsCount(state, count) {
        state.receipts_count = count;
    },
    updateReceiptsLoading(state, loading) {
        state.receipts_loading = loading;
    },
    updateReceiptsLoadingErrors(state, errors) {
        state.receipts_loading_errors = errors;
    },
    updateDemoStarted(state, started) {
        state.demo_started = started;
    },
    updateDemoChecked(state, checked) {
        state.demo_checked = checked;
    },
    updateReceiptsFilters(state, receipts_filters) {
        state.receipts_filters = Object.assign(
            {
                limit: state.receipts_filters.limit || 20,
                ordering: state.receipts_filters.ordering,
            },
            getModeFilters(state.section),
            receipts_filters);
    },
    updateReceiptsCancelSource(state, source) {
        state.receipts_cancel_source = source;
    },
    updateReceiptsStats(state, receipts_stats) {
        state.receipts_stats = receipts_stats;
    },
    updateReceiptsStatsLoading(state, loading) {
        state.receipts_stats_loading = loading;
    },
    updateReceiptsStatsLoadingErrors(state, errors) {
        state.receipts_stats_loading_errors = errors;
    },
    updateReceiptsStatsCancelSource(state, source) {
        state.receipts_stats_cancel_source = source;
    },

};


const actions = {
    changeSection({ commit, dispatch, state }, mode) {
        commit('updateSection', mode);
        dispatch('fetchReceipts');
        dispatch('session/fetchStats', null, { root: true });
    },

    exportReceiptItemssAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters.receipt_ready = state.receipts_filters.ready;
            filters.receipt_readonly = state.receipts_filters.readonly;
            filters.receipt_status = state.receipts_filters.status;
            filters.ordering = state.receipts_filters.ordering;
            filters.exclude_readonly_receipts = state.receipts_filters.exclude_readonly;
            filters.receipt_date_around = state.receipts_filters.receipt_date_around;
            filters.provider = state.receipts_filters.provider;
            filters.customer = state.receipts_filters.customer;
            filters.stock = state.receipts_filters.stock;
            filters.search = state.receipts_filters.search;
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 5000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/receiptitems/?" + queryparams);
    },

    exportReceiptsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.receipts_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/receipts/?" + queryparams);
    },

    fetchReceipts({ commit, dispatch, state }, params) {
        commit('updateReceiptsLoading', true);
        commit('updateReceiptsLoadingErrors', null);

        if (state.receipts_cancel_source) {
            state.receipts_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateReceiptsCancelSource', cancelSource);


        return new Promise((resolve, reject) => {

            axios.get('/api/receipts/', {
                params: state.receipts_filters,
                cancelToken: state.receipts_cancel_source?.token

            })
                .then((response) => {
                    commit('updateReceipts', response.data.results);
                    commit('updateReceiptsCount', response.data.count);
                    commit('updateReceiptsLoading', false);
                    commit('updateReceiptsCancelSource', null);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    if (axios.isCancel(xhr_error)) {
                        return;
                    }
                    const error = utils.handleError(xhr_error);
                    commit('updateReceiptsLoadingErrors', error.details);
                    commit('updateReceiptsLoading', false);
                    commit('updateReceiptsCancelSource', null);
                    reject(error);
                })
                .finally(() => {
                    dispatch('fetchReceiptsStats');
                    dispatch('checkForDemo');
                })
        });
    },

    async fetchReceiptsStats({ commit, state }) {
        commit('updateReceiptsStatsLoading', true);
        commit('updateReceiptsStatsLoadingErrors', null);

        if (state.receipts_stats_cancel_source) {
            state.receipts_stats_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateReceiptsStatsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/receipts/stats/', {
                params: state.receipts_filters,
                cancelToken: state.receipts_stats_cancel_source?.token
            });
            commit('updateReceiptsStats', response.data);
            commit('updateReceiptsStatsCancelSource', null);
            return response.data;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateReceiptsStatsLoadingErrors', error.details);
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateReceiptsStatsLoading', false);
        }
    },

    async checkForDemo({ commit, dispatch, state }, params) {

        let current_user_demo = store.getters['session/current_user_demo'];
        let current_user_impersonated = store.getters['session/current_user_impersonated'];
        if (!current_user_demo || current_user_impersonated || state.demo_started || !state.receipts.length) {
            return;
        }

        commit('updateDemoChecked', true);
        axios.post('/api/demo/start/', { section: 'receipts' })
            .then(() => {
                commit('updateDemoStarted', true);
            })
    },


    placeReceipt({ commit, dispatch, state }, params) {
        const url = `/api/receipts/${params.receipt.receipt_id}/place/`;

        return new Promise((resolve, reject) => {
            axios.put(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                })
                .finally(() => {
                    dispatch('fetchReceipts');
                    dispatch('session/fetchStats', null, { root: true });
                })

        });
    },


    assignReceipt({ commit, dispatch, state }, params) {
        const url = `/api/receipts/${params.receipt.receipt_id}/assign/`;

        return new Promise((resolve, reject) => {
            axios.put(url, { user: params.user })
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                }).finally(() => {
                    dispatch('fetchReceipts');
                    dispatch('session/fetchStats', null, { root: true });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
