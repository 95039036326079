import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import invitations from "./submodules/invitations";

const state = {
    search: null,
    searching: false,
    searching_errors: false,
    results: [],
    provider: null,
    usemail: false,
    email: null,
    name: null,
};

const mutations = {
    updateSearch(state, search) {
        state.search = search;
    },
    updateSearching(state, saving) {
        state.searching = saving;
    },
    updateSearchingErrors(state, errors) {
        state.searching = false;
        state.searching_errors = errors;
    },
    updateResults(state, results) {
        state.results = results;
    },
    updateProvider(state, provider) {
        state.provider = provider;
    },
    updateUsemail(state, usemail) {
        state.usemail = usemail;
    },
    updateEmail(state, email) {
        state.email = email;
    },
    updateName(state, name) {
        state.name = name;
    },
};

const actions = {

    async searchProvider({ commit, dispatch, state }, params) {
        if (!state.search) {
            commit("updateResults", []);
            return;
        }
        commit("updateSearching", true);

        try {
            let response = await axios.get("/api/searchentities/", { params: { search: state.search, exclude_providers: true } })
            commit("updateResults", response.data);
            commit("updateSearching", false);
            return response;
        } catch (xhr_error) {
            let errors = utils.handleError(xhr_error);
            commit("updateSearchingErrors", errors.details);
            throw errors
        }
    },


    async inviteProvider({ commit, dispatch, state }, params) {
        try {
            const response = await axios.post("/api/providers/", {
                name: state.provider?.name || state.name,
                email_or_safetee_id: state.provider?.safetee_id || state.email
            });
            return response.data;
        } catch (xhr_error) {
            let errors = utils.handleError(xhr_error);
            throw errors
        }
    },

    init({ commit, dispatch, state }, params) {
        commit("updateSearch", null);
        commit("updateEmail", null);
        commit("updateName", null);
        commit("updateUsemail", false);
        commit("updateProvider", null);
        commit("updateResults", []);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        invitations: invitations(),
    }
};
