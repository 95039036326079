import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import store from '@/stores/store';
import catalogassignments from './submodules/catalogassignments';
import catalogagentassignments from './submodules/catalogagentassignments';
import catalogentries from './submodules/catalogentries';
import products from './submodules/products';

const state = {
    mode: 'operator',
    section: null,

    catalog: {},
    catalog_loading: false,
    catalog_loading_errors: null,
    catalog_saving: false,
    catalog_saving_errors: {},
    catalog_deleting: false,
    catalog_deleting_errors: null,

    catalogpublishing: null,
    catalogpublishing_loading: false,
    catalogpublishing_loading_errors: null,

    catalogs: [],
    catalogs_count: 0,
    catalogs_loading: false,
    catalogs_loading_errors: null,

    catalogentry: false,
    catalogentry_loading: false,
    catalogentry_loading_errors: null,
    catalogentry_saving: false,
    catalogentry_saving_errors: {},

    products: [],
    products_count: 0,
    products_filters: {},
    products_loaded: false,
    products_loading: false,
    products_loading_error: null,
};


const mutations = {
    updateSection(state, section) {
        state.section = section;
    },
    updateProducts(state, products) {
        state.products = products;
        state.products_loaded = true;
        state.products_loading = false;
        state.products_loading_errors = null;
    },
    updateProductsCount(state, count) {
        state.products_count = count;
    },
    updateProductsLoaded(state, loaded) {
        state.products_loaded = loaded;
    },
    updateProductsLoading(state, products_loading) {
        state.products_loading = products_loading;
    },
    updateProductsLoadingErrors(state, products_loading_errors) {
        state.products_loading_errors = products_loading_errors;
        state.products_loading = false;
    },
    updateProductsFilters(state, products_filters) {
        state.products_filters = products_filters;
    },

    updateCatalog(state, catalog) {
        state.catalog = catalog;
        state.catalog_loading = false;
        state.catalog_loading_errors = null;

        if (catalog && state.catalog && state.catalog.id == catalog.id) {
            return;
        }

        state.products = [];
        state.products_count = 0;
        state.products_loaded = false;
        state.products_loading = false;
        state.products_loading_error = null;
        state.products_filters = {
            type: 'product', offset: 0, limit: 20, catalog: catalog?.id, ordering: 'product_name',
            include_all_products: false,
        };
    },

    updateCatalogLoading(state) {
        state.catalog_loading = true;
        state.catalog_loading_errors = null;
    },
    updateCatalogLoadingErrors(state, errors) {
        state.catalog_loading = false;
        state.catalog_loading_errors = errors;
    },
    updateCatalogSaving(state, saving) {
        state.catalog_saving = saving;
    },
    updateCatalogSavingErrors(state, errors) {
        state.catalog_saving = false;
        state.catalog_saving_errors = errors || {};
    },
    updateCatalogDeleting(state, deleting) {
        state.catalog_deleting = deleting;
    },
    updateCatalogDeletingErrors(state, errors) {
        state.catalog_deleting_errors = errors;
    },

    updateCatalogs(state, catalogs) {
        state.catalogs = catalogs;
    },
    updateCatalogsCount(state, count) {
        state.catalogs_count = count;
    },
    updateCatalogsLoading(state, loading) {
        state.catalogs_loading = loading;
    },
    updateCatalogsLoadingErrors(state, errors) {
        state.catalogs_loading_errors = errors;
    },

    updateCatalogEntry(state, catalogentry) {
        state.catalogentry = catalogentry;
    },
    updateCatalogEntryLoading(state, loading) {
        state.catalogentry_loading = loading;
    },
    updateCatalogEntryLoadingErrors(state, errors) {
        state.catalogentry_loading_errors = errors;
    },
    updateCatalogEntrySaving(state, saving) {
        state.catalogentry_saving = saving;
        state.catalogentry_saving_errors = null;
    },
    updateCatalogEntrySavingErrors(state, errors) {
        state.catalogentry_saving = false;
        state.catalogentry_saving_errors = errors;
    },



    updateCatalogProductsSaving(state) {
        state.catalog_products_saving = true;
    },
    updateCatalogProductsSavingErrors(state, errors) {
        state.catalog_products_saving = false;
        state.catalog_products_saving_errors = errors;
    },

    updateCatalogPublishing(state, catalogpublishing) {
        state.catalogpublishing = catalogpublishing;
    },
    updateCatalogPublishingLoading(state, loading) {
        state.catalogpublishing_loading = loading;
    },
    updateCatalogPublishingLoadingErrors(state, errors) {
        state.catalogpublishing_loading_errors = errors;
    },


    updatePurchasePrice(state, params) {
        if (params.price == "") {
            params.price = null;
        }
        // Lookup entry in products and modify in place
        const product = state.products.find((product) => product.id == params.product);
        if (product) {
            if (product.catalogentry_data) {
                product.catalogentry_data.price = params.price;
            } else {
                product.catalogentry_data = { price: params.price };
            }
        }
    },
    updateProductAgentCommission(state, params) {
        if (params.agent_commission == "") {
            params.agent_commission = null;
        }
        // Lookup entry in products and modify in place
        const product = state.products.find((product) => product.id == params.product);
        if (product) {
            if (product.catalogentry_data) {
                product.catalogentry_data.agent_commission = params.agent_commission;
            } else {
                product.catalogentry_data = { agent_commission: params.agent_commission };
            }
        }
    },
};


const getters = {

    // getPurchasePrice: (state) => (product_id) => {
    //     if (state.new_data[product_id]?.price) {
    //         return state.new_data[product_id].price;
    //     }
    //     const product = state.products.find((product) => product.id == product_id)
    //     if (!product || !product.catalogentry) {
    //         return null;
    //     }
    //     return product.catalogentry.price;
    // },

    // getProductAgentCommission: (state) => (product_id) => {
    //     if (state.new_data[product_id]?.agent_commission) {
    //         return state.new_data[product_id].agent_commission;
    //     }
    //     const product = state.products.find((product) => product.id == product_id)
    //     if (!product || !product.catalogentry) {
    //         return null;
    //     }
    //     return product.catalogentry.agent_commission;
    // }
};

const actions = {

    exportProductCatalogEntriesToCSV({ commit, dispatch, state }, params) {
        let filters = {
            ...{
                format: 'csv',
                limit: 10000,
                catalog: state.catalog.id,
                include_all_products: false,
                ordering: name,
                type: 'product',
            }, ...(params || {})
        };
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open(`/api/productcatalogentries/?${queryparams}`);
    },


    exportToCSV({ commit, dispatch, state }, params) {
        let filters = {
            ...{
                format: 'csv',
                limit: 10000,
                catalog: state.catalog.id,
            }, ...(params || {})
        };
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open(`/api/catalogentries/?${queryparams}`);
    },

    fetchCatalog({ commit, dispatch, state }, params) {
        commit('updateCatalogLoading', true);

        return new Promise((resolve, reject) => {
            let url;
            if (params?.catalog_id) {
                url = `/api/catalogs/${params.catalog_id}/`;
            } else if (state?.catalog) {
                url = `/api/catalogs/${state.catalog.id}/`;
            } else {
                throw 'No catalog to fetch';
            }
            axios.get(url)
                .then((response) => {
                    commit('updateCatalogLoading', false);
                    commit('updateCatalog', response.data);
                    dispatch('fetchCatalogPublishing');
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogLoadingErrors', error.details);
                    reject(error)
                });
        });
    },

    fetchCatalogEntry({ commit, dispatch, state }, params) {
        commit('updateCatalogEntryLoading', true);
        commit('updateCatalogEntryLoadingErrors', null);

        return new Promise((resolve, reject) => {

            let url;
            if (params && params.id) {
                url = `/api/catalogentries/${params.id}/`;
            } else if (state.catalog) {
                url = `/api/catalogentries/${state.catalogentry.id}/`;
            } else {
                throw 'No catalogentry to fetch';
            }

            axios.get(url)
                .then((response) => {
                    commit('updateCatalogEntryLoading', false);
                    commit('updateCatalogEntry', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogEntryLoading', false);
                    commit('updateCatalogEntryLoadingErrors', error.details);
                    reject(error)
                });
        });
    },

    setCatalogAsDefault({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/catalogs/${state.catalog.id}/default/`;
            axios.post(url)
                .then((response) => {
                    resolve(response);
                    dispatch('fetchCatalog')
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    deleteCatalog({ commit, dispatch, state }, params) {
        commit('updateCatalogDeleting', true);
        commit('updateCatalogDeletingErrors', null);

        return new Promise((resolve, reject) => {

            const url = `/api/catalogs/${params.instance.id}/`;
            axios.delete(url)
                .then((response) => {
                    commit('updateCatalogDeleting', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogDeleting', false);
                    commit('updateCatalogDeletingErrors', error.details);
                    reject(error);

                });
        });
    },


    async deleteCatalogEntry({ commit, dispatch, state }, params) {

        try {
            await axios.delete(`/api/catalogentries/${params.catalogentry.id}/`);
        } finally {
            dispatch("fetchProducts");
        }
    },



    async setPurchasePrice({ commit, dispatch, state }, params) {
        try {
            let url = `/api/catalogs/${state.catalog.id}/updateproduct/`;
            let data = {
                product: params.product,
                catalog: state.catalog.id,
                price: params.price,
                agent_commission: params.agent_commission,
            };
            let method = axios.post;

            const response = await method(url, data);
            commit('updatePurchasePrice', params);
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },



    async setProductAgentCommission({ commit, dispatch, state }, params) {
        try {
            let method = axios.post;
            let url = `/api/catalogs/${state.catalog.id}/updateproduct/`;
            let data = {
                product: params.product,
                catalog: state.catalog.id,
                price: params.price,
                agent_commission: params.agent_commission,
            };

            const response = await method(url, data);
            commit('updateProductAgentCommission', params);
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },


    setCatalogEntryStatus({ commit, dispatch, state }, params) {

        return new Promise((resolve, reject) => {
            const url = `/api/catalogentries/${params.catalogentry.id}/status/`;
            const data = {
                status: params.status,
            };

            if (params.received_count) {
                data.received_count = params.received_count;
            }

            axios.put(url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    saveCatalog({ commit, dispatch, state }, params) {
        commit('updateCatalogSaving', true);
        commit('updateCatalogSavingErrors', null);

        return new Promise((resolve, reject) => {
            let url = '/api/catalogs/';
            let method = axios.post;

            if (params.instance.id) {
                url = `/api/catalogs/${params.instance.id}/`;
                method = axios.put;
            }
            method(url, params.instance)
                .then((response) => {
                    commit('updateCatalogSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogSavingErrors', error.details);
                    reject(error);
                });
        });
    },



    fetchCatalogs({ commit, dispatch, state }, params) {
        commit('updateCatalogsLoading', true);
        commit('updateCatalogsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/catalogs/', { params: { limit: 1000 } })
                .then((response) => {
                    commit('updateCatalogsLoading', false);
                    commit('updateCatalogs', response.data.results);
                    commit('updateCatalogsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogsLoadingErrors', error.details);
                    commit('updateCatalogsLoading', false);
                    reject(error);
                });
        });
    },

    fetchTestShipments({ commit, dispatch, state }, params) {
        commit('updateTestShipmentsLoading', true);
        commit('updateTestShipmentsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/shipments/', { params: { limit: 100, ordering: '-delivery_date' } })
                .then((response) => {
                    commit('updateTestShipments', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateTestShipmentsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateTestShipmentsLoading', false);
                })
        });
    },

    fetchTestReceipts({ commit, dispatch, state }, params) {
        commit('updateTestReceiptsLoading', true);
        commit('updateTestReceiptsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/receipts/', { params: { limit: 100, ordering: '-receipt_date' } })
                .then((response) => {
                    commit('updateTestReceipts', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateTestReceiptsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateTestReceiptsLoading', false);
                })
        });
    },

    fetchTestCustomers({ commit, dispatch, state }, params) {
        commit('updateTestCustomersLoading', true);
        commit('updateTestCustomersLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/entities/', { params: { limit: 100, ordering: 'name', profile: "customer" } })
                .then((response) => {
                    commit('updateTestCustomers', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateTestCustomersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateTestCustomersLoading', false);
                })
        });
    },

    fetchProducts({ commit, dispatch, state }, params) {
        if (store.getters['session/current_entity_profile'] != 'customer') {
            return;
        }
        commit('updateProductsLoading', true);

        return new Promise((resolve, reject) => {
            axios.get('/api/productcatalogentries/', { params: state.products_filters })
                .then((response) => {
                    commit('updateProducts', response.data.results);
                    commit('updateProductsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductsLoadingErrors', error.details);
                    reject(error);
                })
        });
    },


    save({ commit, dispatch, state }, params) {
        commit('updateCatalogProductsSaving', true);

        return new Promise((resolve, reject) => {
            const url = `/api/catalogs/${state.catalog.id}/updateproducts/`;
            // merge list of products with new prices keys and new commissions keys, generate a unique list of products with prices and commissions. A product can be on new_prices and not in new_data and vice versa.

            // list of {product: product, price: price, agent_commission: agent_commission}
            const payload = Object.entries(state.new_data).map(([product, data]) => {
                return { product: product, ...data };
            });

            axios.post(url, payload)
                .then((response) => {
                    commit('updateCatalogProductsSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateCatalogProductsSavingErrors', error.details);
                    reject(error);
                });
        });
    },

    async fetchCatalogPublishing({ commit, dispatch, state }) {
        commit('updateCatalogPublishingLoading', true);
        commit('updateCatalogPublishingLoadingErrors', null);

        try {
            const response = await axios.get('/api/catalogpublishings/', { params: { catalog: state.catalog.id } });
            if (response.data.count) {
                commit('updateCatalogPublishing', response.data.results[0]);
            } else {
                commit('updateCatalogPublishing', null);
            }
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCatalogPublishingLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateCatalogPublishingLoading', false);
        }
    },

    async init({ commit, dispatch, state }, params) {
        await commit('updateCatalog', null);
        await commit('updateProducts', []);

        if (!params?.catalog) {
            return;
        }

        if (!params.catalog?.id) {
            commit('updateProductsFilters', {
                type: 'product',
                ordering: 'product_name',
                include_all_products: false,
            });
            return;
        }
        await commit('updateProductsFilters', {
            catalog: params.catalog.id,
            type: 'product',
            ordering: 'product_name',
            include_all_products: false,
        });
        await dispatch('entries/init', {
            catalog: params.catalog,
            filters: {
                catalog: params.catalog.id,
                ordering: 'name',
            }
        });
        await dispatch('services/init', {
            catalog: params.catalog, filters: {
                catalog: params.catalog.id,
                type: 'service',
                ordering: 'name',
            }
        });
        await commit('updateSection', params.section || null);
        await commit('updateCatalog', params.catalog);
        await dispatch("session/fetchStats", null, { root: true });
        await dispatch('customers_assignments/fetchCatalogAssignments');

        if (store.getters['session/current_user_mode'] != "operator") {
            dispatch('fetchCatalogPublishing');
            if (params.catalog.type == 'sell') {
                dispatch('agents_assignments/init', { catalog: params.catalog, filters: {} });
                dispatch('agents_assignments/fetchCatalogAgentAssignments');

                dispatch('customers_assignments/init', {
                    catalog: params.catalog
                });
            }
        }

    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        customers_assignments: catalogassignments(),
        agents_assignments: catalogagentassignments(),
        entries: catalogentries(),
        services: catalogentries(),
    }
};
