import store from "@/stores/store";
import axios from "axios";
import utils from "@/stores/utils";
import orders from "./submodules/orders";
import documents from "./submodules/documents";
import catalogentries from "./submodules/catalogentries";
import catalogs from "./submodules/catalogs";
import invoices from "./submodules/invoices";

const state = {
    order: null,
    order_loaded: false,
    order_loading: false,
    order_loading_errors: null,
    order_saving: false,
    order_saving_errors: null,

    order_setting_readyness: false,
    updating_order_status: false,
    assigning_order: false,

    deleting_order: false,
    deleting_order_errors: null,

    orderitem: false,
    orderitem_loading: false,
    orderitem_loading_errors: null,
    orderitem_saving: false,
    orderitem_saving_errors: null,
    orderitem_deleting: false,
    orderitem_deleting_errors: null,

    orderitems: null,
    orderitems_count: 0,
    orderitems_filters: { limit: 20, ordering: "id" },
    orderitems_loaded: false,
    orderitems_loading: false,
    orderitems_loading_errors: null,
    orderitems_cancel_source: null,

    customers: [],
    customers_count: 0,
    customers_loading: false,
    customers_loading_errors: null,

    deliverysites: [],
    deliverysites_count: 0,
    deliverysites_loading: false,
    deliverysites_loading_errors: null,

    stocks: [],
    stocks_count: 0,
    stocks_loading: false,
    stocks_loading_errors: null,

    events: [],
    events_filters: {},
    events_loading: false,
    events_loaded: false,
    events_loading_errors: null,
    events_count: 0,

    shipments: [],
    shipments_filters: {},
    shipments_count: 0,
    shipments_loaded: false,
    shipments_loading: false,
    shipments_loading_errors: null,

    order_delegating: false,
    order_billing: false,
    creating_suborders: false,

    billingaccount: null,
};

const mutations = {
    updateOrder(state, order) {
        state.order_loaded = true;

        if (order && state.order && state.order.id == order.id) {
            state.order = order;
            return;
        }

        state.order = order;

        state.orderitems = [];
        state.orderitems_count = 0;
        state.orderitems_loaded = false;

        state.events = [];
        state.events_count = 0;
        state.events_loaded = false;
        state.events_loading_errors = null;
        if (!order) {
            state.orderitems_filters = {
                offset: 0,
                limit: state.orderitems_filters.limit,
                ordering: "id"
            };
            state.events_filters = { object_type: "order", ordering: "event_date" };
            return;
        }

        state.orderitems_filters = { offset: 0, limit: state.orderitems_filters.limit, ordering: "id" };
        state.events_filters = {
            object_type: "order",
            object_id: order.order_id,
            ordering: "event_date",
        };

        state.shipments = [];
        state.shipments_count = 0;
        state.shipments_loaded = false;
        state.shipments_filters = {
            offset: 0,
            limit: state.shipments_filters.limit,
        };
        if (state.orderitems_cancel_source) {
            state.orderitems_cancel_source.cancel("canceled");
            state.orderitems_cancel_source = null;
        }
    },
    updateOrderLoading(state, loading) {
        state.order_loading = loading;
    },
    updateOrderLoadingErrors(state, errors) {
        state.order_loaded = true;
        state.order_loading_errors = errors;
    },
    updateOrderSaving(state, saving) {
        state.order_saving = saving;
    },
    updateOrderSavingErrors(state, errors) {
        state.order_saving_errors = errors;
    },

    updateSettingOrderReadyness(state, setting) {
        state.order_setting_readyness = setting;
    },
    updateUpdatingOrderStatus(state, status) {
        state.updating_order_status = status;
    },
    updateOrderDeleting(state, deleting) {
        state.deleting_order = deleting;
    },
    updateOrderDeletingErrors(state, errors) {
        state.deleting_order_errors = errors;
    },
    updateOrderItem(state, orderitem) {
        state.orderitem = orderitem;
    },
    updateOrderItemLoading(state, loading) {
        state.orderitem_loading = loading;
    },
    updateOrderItemLoadingErrors(state, errors) {
        state.orderitem_loading_errors = errors;
    },
    updateOrderItemSaving(state, saving) {
        state.orderitem_saving = saving;
    },
    updateOrderItemSavingErrors(state, errors) {
        state.orderitem_saving_errors = errors;
    },
    updateOrderItemDeleting(state, deleting) {
        state.orderitem_deleting = deleting;
    },
    updateOrderItemDeletingErrors(state, errors) {
        state.orderitem_deleting_errors = errors;
    },
    updateOrderItems(state, orderitems) {
        state.orderitems = orderitems;
    },
    updateOrderItemsCount(state, count) {
        state.orderitems_count = count;
    },
    updateOrderItemsFilters(state, orderitems_filters) {
        state.orderitems_filters = {
            ...orderitems_filters,

        };
    },
    updateOrderItemsLoading(state, loading) {
        state.orderitems_loading = loading;
    },
    updateOrderItemsLoaded(state, loaded) {
        state.orderitems_loaded = loaded;
    },
    updateOrderItemsLoadingErrors(state, errors) {
        state.orderitems_loading_errors = errors;
    },
    updateOrderItemsCancelSource(state, orderitems_cancel_source) {
        state.orderitems_cancel_source = orderitems_cancel_source;
    },
    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersCount(state, count) {
        state.customers_count = count;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },
    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
    },
    updateDeliverySitesCount(state, count) {
        state.deliverysites_count = count;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },
    updateStocks(state, stocks) {
        state.stocks = stocks;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },
    updateStocksCount(state, count) {
        state.stocks_count = count;
    },
    updateAssigningOrder(state, assigning_order) {
        state.assigning_order = assigning_order;
    },
    updateEvents(state, events) {
        state.events = events;
    },
    updateEventsLoading(state, events_loading) {
        state.events_loading = events_loading;
    },
    updateEventsLoadingErrors(state, events_loading_errors) {
        state.events_loading_errors = events_loading_errors;
    },
    updateEventsCount(state, events_count) {
        state.events_count = events_count;
    },
    updateShipments(state, shipments) {
        state.shipments = shipments;
    },
    updateShipmentsLoading(state, shipments_loading) {
        state.shipments_loading = shipments_loading;
        state.shipments_loaded = true;
    },
    updateShipmentsLoadingErrors(state, shipments_loading_errors) {
        state.shipments_loading_errors = shipments_loading_errors;
    },
    updateShipmentsCount(state, shipments_count) {
        state.shipments_count = shipments_count;
    },
    updateShipmentsFilters(state, shipments_filters) {
        state.shipments_filters = Object.assign(
            {
                limit: state.shipments_filters.limit || 20,
                ordering: state.shipments_filters.ordering,
            },
            shipments_filters
        );
    },
    updateDelegatingOrder(state, order_delegating) {
        state.order_delegating = order_delegating;
    },
    updateCreatingSuborders(state, creating_suborders) {
        state.creating_suborders = creating_suborders;
    },
    updateOrderBilling(state, order_billing) {
        state.order_billing = order_billing;
    },
    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },
};

const actions = {

    async fetchEvents({ commit, dispatch, state }, params) {
        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_event"
            ) == -1
        ) {
            return;
        }
        commit("updateEventsLoading", true);

        try {
            const response = await axios.get("/api/events/", {
                params: state.events_filters,
            });
            commit("updateEvents", response.data.results);
            commit("updateEventsCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateEventsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateEventsLoading", false);
        }
    },

    async fetchShipments({ commit, dispatch, state }, params) {

        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_shipment"
            ) == -1
        ) {
            return;
        }

        commit("updateShipmentsLoading", true);

        try {
            const response = await axios.get(`/api/customerorders/${state.order.id}/shipments/`, {
                params: state.shipments_filters,
            });
            commit("updateShipments", response.data.results);
            commit("updateShipmentsCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateShipmentsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateShipmentsLoading", false);
        }
    },

    async fetchOrder({ commit, dispatch, state }, params) {

        commit("updateOrderLoading", true);
        commit("updateOrderLoadingErrors", null);

        let url = `/api/customerorders/${state.order.id}/`;

        try {
            const response = await axios.get(url);
            commit("updateOrderLoading", false);
            commit("updateOrder", response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateOrderLoading", false);
            commit("updateOrderLoadingErrors", error.details);
            throw error;
        }
    },

    async csvExport({ commit, dispatch, state }, params) {

        const url = `/api/customerorderitems/?order=${state.order.id}&format=csv`;
        window.open(url, "_blank");
    },

    async setOrderReadyness({ commit, dispatch, state }, params) {

        const url = `/api/customerorders/${state.order.id}/ready/`;
        commit("updateSettingOrderReadyness", true);
        try {
            const response = await axios.put(url, { ready: params.ready });
            dispatch("fetchOrder");
            dispatch("fetchEvents");
            dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit("updateSettingOrderReadyness", false);
        }
    },

    async setOrderStatus({ commit, dispatch, state }, params) {

        const url = `/api/customerorders/${state.order.id}/status/`;

        commit("updateUpdatingOrderStatus", true);

        try {
            const response = await axios.put(url, { status: params.status });
            dispatch("fetchOrder");
            dispatch("fetchEvents");
            dispatch("fetchOrderItems");
            dispatch("fetchShipments");
            if (state.order.suborders_count) {
                dispatch("suborders/fetchOrders");
            }

            dispatch("invoices/fetchInvoices");
            dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit("updateUpdatingOrderStatus", false);
        }
    },

    async markAsPaid({ commit, dispatch, state }, params) {

        commit("updateOrderSaving", true);
        const url = `/api/customerorders/${state.order.id}/paid/`;

        try {
            const response = await axios.put(url, { paid: params.paid });
            dispatch("fetchOrder");
            dispatch("fetchEvents");
            dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit("updateOrderSaving", false);
        }
    },

    async deleteOrder({ commit, dispatch, state }, params) {

        const url = `/api/customerorders/${params.instance.id}/`;

        commit("updateOrderDeleting", true);
        commit("updateOrderDeletingErrors", null);

        try {
            const response = await axios.delete(url);
            commit("updateOrderDeleting", false);
            await dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateOrderDeleting", false);
            commit("updateOrderDeletingErrors", error.details);
            throw error;
        }
    },

    async deleteOrderItem({ commit, dispatch, state }, params) {
        commit("updateOrderItemDeleting", true);
        commit("updateOrderItemDeletingErrors", null);

        const url = `/api/customerorderitems/${params.instance.id}/`;

        try {
            const response = await axios.delete(url);
            commit("updateOrderItemDeleting", false);
            await Promise.all([
                dispatch("fetchOrder"),
                dispatch("fetchOrderItems"),
                dispatch("fetchEvents"),
                dispatch("session/fetchStats", null, { root: true }),
            ]);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateOrderItemDeleting", false);
            commit("updateOrderItemDeletingErrors", error.details);
            throw error;
        }
    },

    async saveOrder({ commit, dispatch, state }, params) {

        commit("updateOrderSaving", true);
        commit("updateOrderSavingErrors", null);

        let url = "/api/customerorders/";
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/customerorders/${params.instance.id}/`;
            method = axios.put;
        }

        try {
            const response = await method(url, params.instance);
            commit("updateOrderSaving", false);
            if (params.instance.id) {
                await dispatch("fetchOrder");
            }
            await dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateOrderSaving", false);
            commit("updateOrderSavingErrors", error.details);
            throw error;
        }
    },

    async fetchOrderItems({ commit, dispatch, state }, params) {
        commit("updateOrderItemsLoading", true);
        commit("updateOrderItemsLoadingErrors", null);

        if (state.orderitems_cancel_source) {
            state.orderitems_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit("updateOrderItemsCancelSource", cancelSource);

        try {
            const response = await axios.get("/api/customerorderitems/", {
                params: {
                    order: state.order.id,
                    stock: state.order.stock,
                    ...state.orderitems_filters,
                },
                cancelToken: state.orderitems_cancel_source?.token
            });
            commit("updateOrderItems", response.data.results);
            commit("updateOrderItemsCount", response.data.count);
            commit("updateOrderItemsCancelSource", null);
            commit("updateOrderItemsLoaded", true);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit("updateOrderItemsLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateOrderItemsLoading", false);
        }
    },

    async fetchCustomers({ commit, dispatch, state }, params) {

        commit("updateCustomersLoading", true);
        commit("updateCustomersLoadingErrors", null);

        try {
            const response = await axios.get("/api/entities/", {
                params: { limit: 1000, ordering: "name", profile: "customer" },
            });
            commit("updateCustomers", response.data.results);
            commit("updateCustomersCount", response.data.count);
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit("updateCustomersLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateCustomersLoading", false);
        }
    },

    async fetchDeliverySites({ commit }, { customer_id }) {
        if (
            store.getters["session/current_user_permissions"].indexOf(
                "core.view_deliverysite"
            ) == -1
        ) {
            return;
        }

        commit("updateDeliverySitesLoading", true);
        commit("updateDeliverySitesLoadingErrors", null);

        try {

            const response = await axios.get(`/api/customers/${customer_id}/deliverysites/`, {
                params: { limit: 1000, ordering: "name", customer: customer_id },
            });
            commit("updateDeliverySites", response.data.results);
            commit("updateDeliverySitesCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateDeliverySitesLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateDeliverySitesLoading", false);
        }
    },

    async fetchStocks({ commit, dispatch, state }, params) {

        commit("updateStocksLoading", true);
        commit("updateStocksLoadingErrors", null);

        try {
            const response = await axios.get("/api/stocks/", {
                params: { limit: 1000 },
            });
            commit("updateStocks", response.data.results);
            commit("updateStocksCount", response.data.count);
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit("updateStocksLoadingErrors", error.details);
            throw error;
        } finally {
            commit("updateStocksLoading", false);
        }
    },


    async fetchBillingAccount({ commit, dispatch, state }, params) {
        if (!state.order.buyer_data.billingaccount || state.order.buyer_data.owner != store.getters["session/current_entity_id"]) {
            return;
        }
        try {
            const response = await axios.get(`/api/billingaccounts/${state.order.buyer_data.billingaccount}/`);
            commit("updateBillingAccount", response.data);
            return response;
        } catch (xhr_error) {
            commit("updateBillingAccount", null);
            let error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async assignOrder({ commit, dispatch, state }, params) {

        const url = `/api/customerorders/${state.order.id}/assign/`;
        commit("updateAssigningOrder", true);

        try {
            const response = await axios.put(url, { user: params.user });
            dispatch("fetchOrder");
            commit("updateAssigningOrder", false);
            dispatch("fetchEvents");
            dispatch("session/fetchStats", null, { root: true });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async billOrder({ commit, dispatch, state }, params) {

        const url = `/api/customerorders/${state.order.id}/bill/`;
        commit("updateOrderBilling", true);

        try {
            const response = await axios.post(url, { force: params.force });
            commit("updateOrderBilling", false);
            dispatch("fetchOrder");
            dispatch("invoices/fetchInvoices");
            dispatch("session/fetchStats", null, { root: true });
            return response.data;
        } catch (xhr_error) {
            commit("updateOrderBilling", false);
            throw utils.handleError(xhr_error);
        }
    },


    async createSubOrders({ commit, dispatch, state }, params) {

        const url = `/api/customerorders/${state.order.id}/createsuborders/`;
        commit("updateCreatingSuborders", true);

        try {
            const response = await axios.post(url);
            commit("updateCreatingSuborders", false);
            dispatch('update');
            return response;
        } catch (xhr_error) {
            commit("updateCreatingSuborders", false);
            throw utils.handleError(xhr_error);
        }
    },

    async init({ commit, dispatch, state }, { order }) {
        await commit("updateOrderItemsLoaded", false);
        await commit("updateBillingAccount", null);
        await commit("updateOrderItems", []);

        await dispatch("documents/init", {
            filterTag: `order:${order.order_id}`,
            createTags: [`order: ${order.order_id}`],
        });

        await dispatch("catalogentries/init", {
            url: `/api/customerorders/${order.id}/catalogentries/`,
            filters: {
                exclude_order_products: order.id,
                ordering: "name", type: 'product'
            },
        });
        await dispatch("catalogs/init", {
            url: `/api/customerorders/${order.id}/catalogs/`,
            filters: {
                ordering: "name"
            },
        });

        await dispatch("invoices/init", {
            url: `/api/customerorders/${order.id}/invoices/`,
            filters: { order: order.id, include_suborders: true },
        });

        await dispatch("suborders/init", {
            url: `/api/customerorders/${order.id}/suborders/`
        });
        await commit("updateOrder", order);

    },

    update({ commit, dispatch, state }) {
        dispatch("fetchOrder");
        dispatch('fetchBillingAccount');
        dispatch("fetchOrderItems");
        dispatch("catalogs/fetchCatalogs");
        dispatch("fetchEvents");
        dispatch("fetchShipments");
        if (state.order.suborders_count) {
            dispatch("suborders/fetchOrders");
        }
        dispatch("invoices/fetchInvoices");
        dispatch("documents/fetchDocuments");
        dispatch("session/fetchStats", null, { root: true });
    },

    downloadOrderForm({ commit, dispatch, state }, params) {
        const url = `/ api / customerorders / ${state.order.id} /form/`;
        window.open(url, "_blank");
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        suborders: orders(),
        catalogs: catalogs(),
        documents: documents(),
        catalogentries: catalogentries(),
        invoices: invoices(),
    },
};
