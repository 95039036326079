<template>
    <div
        class="d-block d-sm-flex mb-8 flex-wrap title__wrapper align-center flex-wrap"
        :class="className"
    >
        <div class="d-flex flex-grow-1 align-center">
            <div class="title">
                <div class="d-flex align-center">
                    <v-btn
                        @click="$router.back()"
                        text
                        small
                        class="px-0 b mr-5 white back__button mr-2 d-none d-sm-block"
                        cusor="pointer"
                        v-if="back"
                    >
                        <v-icon color="primary">{{ iconName("back") }}</v-icon>
                    </v-btn>
                    <div class="d-flex align-center">
                        <v-icon
                            color="primary-dark"
                            class="mr-2 d-none d-sm-block"
                            size="50"
                            v-if="icon"
                            >{{ iconName(icon) }}</v-icon
                        >
                        <div class="d-sm-flex d-block align-baseline">
                            <div class="text-h1 primary-dark--text title__text">
                                {{ text }}
                                <template v-if="tags.length">
                                    <v-chip
                                        color="primary-dark white--text font-weight-bold "
                                        class="ml-2"
                                        v-for="tag in tags"
                                        :key="tag"
                                    >
                                        {{ tag }}
                                    </v-chip></template
                                >
                            </div>

                            <div
                                class="text-subtitle-2 mt-0 ml-2 primary-grey--text"
                                v-if="subtitle"
                                v-text="subtitle"
                            />
                            <v-icon
                                v-if="helpContentId && uniqueIdExists"
                                class="ml-4"
                                left
                                @click="openHelp(helpContentId)"
                                >help_outline</v-icon
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="d-flex flex-wrap justify-end flex-grow-1 w-sm-100"
            v-if="ordered_actions && ordered_actions.length"
        >
            <template v-for="(action, index) in ordered_actions">
                <v-btn
                    :loading="action.loading"
                    v-if="!action.items"
                    :key="action.action"
                    class="ml-2 my-2 px-7 w-sm-auto w-100"
                    :light="!action.main"
                    :disabled="!actionsEnabled || action.disabled"
                    :text="$vuetify.breakpoint.name == 'xs' ? false : !action.main"
                    :color="
                        action.main
                            ? 'primary'
                            : $vuetify.breakpoint.name == 'xs'
                            ? 'primary-light'
                            : 'primary'
                    "
                    elevation="0"
                    @click="actionClicked(action)"
                >
                    <v-icon
                        small
                        :color="action.main ? 'white' : action.color || 'primary-light'"
                        v-if="action.icon"
                        left
                        >{{ iconName(action.icon) || action.icon }}</v-icon
                    >

                    <div :class="action.main ? 'white--text' : 'primary-text'">
                        {{ action.label }}
                    </div>
                </v-btn>

                <v-menu offset-y v-else :key="index">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :key="action.action"
                            class="ml-2 my-2 px-7 w-sm-auto w-100"
                            light
                            elevation="0"
                            :text="
                                $vuetify.breakpoint.name == 'xs' ? false : !action.main
                            "
                            :color="
                                action.main
                                    ? 'primary'
                                    : $vuetify.breakpoint.name == 'xs'
                                    ? 'primary-light'
                                    : 'primary'
                            "
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon
                                small
                                :color="action.main ? 'white' : 'primary-light'"
                                v-if="action.icon"
                                left
                                >{{ iconName(action.icon) || action.icon }}</v-icon
                            >
                            <div :class="action.main ? 'white--text' : 'primary-text'">
                                {{ action.label }}
                            </div>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in action.items"
                            :key="index"
                            @click="actionClicked(item)"
                        >
                            <v-icon small v-if="item.icon" left :color="item.color">{{
                                iconName(item.icon) || item.icon
                            }}</v-icon
                            >{{ item.label }}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        className: {
            type: String,
            required: false,
        },
        back: {
            type: Boolean,
            required: false,
        },
        text: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        actions: {
            type: Array,
            required: false,
        },
        actionsEnabled: {
            type: Boolean,
            default: true,
        },
        edit: {
            type: Boolean,
            required: false,
        },
        tags: {
            type: Array,
            required: false,
            default: () => [],
        },
        helpContentId: {
            type: String,
            required: false,
        },
    },
    computed: Object.assign(mapState("help", ["uniqueIds"]), {
        ordered_actions() {
            if (!this.actions) {
                return [];
            }
            let actions = [];
            actions = actions.concat(this.actions.filter((item) => !item.main));
            actions = actions.concat(this.actions.filter((item) => item.main));
            return actions;
        },
        uniqueIdExists() {
            if (!this.uniqueIds || !this.helpContentId) {
                return false;
            }
            return this.uniqueIds.includes(this.helpContentId);
        },
    }),
    methods: {
        actionClicked(action) {
            if (action.link) {
                this.routerPush(action.link);
            } else {
                this.$emit(action.action);
            }
        },
    },
};
</script>

<style lang="scss">
.text-h1 {
    font-size: 26px !important;
    line-height: 1em !important;
    font-family: "Poppins" !important;
}

.title__wrapper {
    position: relative;
    .title__text {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .text-h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .back__button {
        height: 40px;
        width: 40px;
    }
}
</style>
